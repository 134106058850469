import React from "react";
import { graphql } from "gatsby";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    layout,
    headingSectionWrapper,
    headingSection,
    title,
    subtitle,
    buttonSection,
} from "./tag-page.module.scss";
import { config } from "../../config";
import QueryEdgeNode from "../../models/query-edge-node.model";
import { IPostBrief } from "../../models/post.model";
import { IAuthor } from "../../models/author.model";
import { ITag } from "../../models/tags.model";
import { IPageBase } from "../../models/page-base.model";
import { useTranslation } from "../../hooks/use-translation";
import getNodes from "../../utils/get-nodes";

import MainLayout from "../../layouts/main-layout";
import Section from "../molecules/section";
import BlogList from "../organisms/blog/blog-list";
import SectionWrapper from "../molecules/section-wrapper";
import BackToAllPostsLink from "../atoms/blog/back-to-all-posts-link";

const { translationKeys } = config;

export interface ITagPageProps extends IPageBase {
    data: {
        allPost: QueryEdgeNode<IPostBrief>;
        allAuthor: QueryEdgeNode<IAuthor>;
        tag: ITag;
    };
}

export default function TagPage({ data, pageContext }: ITagPageProps) {
    const { allPost, allAuthor, tag } = data;
    const { paginationKeys } = pageContext;

    const { t } = useI18next();
    const { name } = useTranslation(tag, translationKeys.tag);

    const posts = getNodes(allPost);
    const authors = getNodes(allAuthor);

    return (
        <MainLayout hasPaddingTop className={layout} SEOProps={{ title: name }}>
            <SectionWrapper bgColor="beige-light" className={headingSectionWrapper}>
                <Section className={headingSection}>
                    <h1 className={title}>#{name}</h1>
                    <p className={subtitle}>
                        {t("tag.subtitle")} <strong>{name}</strong>
                    </p>
                </Section>
            </SectionWrapper>
            <SectionWrapper>
                <Section>
                    <BlogList posts={posts} authors={authors} paginationKeys={paginationKeys} />
                </Section>
                <Section className={buttonSection}>
                    <BackToAllPostsLink />
                </Section>
            </SectionWrapper>
        </MainLayout>
    );
}

export const query = graphql`
    query($language: String!, $postIds: [Int!], $tagId: Int!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allPost(
            filter: { articleId: { in: $postIds } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            edges {
                node {
                    ...postBriefFields
                }
            }
        }

        allAuthor {
            edges {
                node {
                    ...authorShortFields
                }
            }
        }

        tag(tagId: { eq: $tagId }) {
            ...tagFields
        }
    }
`;
